import { ColorsClasses, WeightsClasses } from '../../constants';
import { IValidation } from '../../interfaces';
import { Input } from 'antd';
import React from 'react';
import './TextArea.css'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const AntTextArea = Input.TextArea;

interface Props {
  title: string;
  placeholder: string;
  id?: string;
  textAreaClass?: string;
  maxHeight?: string;
  value: string;
  rows?: number;
  name: string;
  simple?: boolean;
  simplestate?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  validation?: IValidation | boolean;
}

const TextArea: React.FC<Props> = ({ title, placeholder, id, textAreaClass, maxHeight, value, rows = 4, name, simple = false, simplestate = false, disabled = false, onChange, validation }) => {
  const isValidationObject = typeof validation === 'object' && validation !== null;
  const validator = isValidationObject ? validation?.Validator?.() : validation === true;

  return (
    <div className={textAreaClass}>
      {
        (!simple || !simplestate) && (
          <div className='flex'>
            <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>

            {
              validation !== null && (isValidationObject || (typeof validation === "boolean") && validation) && (
                <span className="text-left block mb-2 text-sm font-medium text-red-600 ms-1">(*)</span>
              )
            }
          </div>
        )
      }

      <div className='relative'>
        <AntTextArea
          className={`
            bg-white border border-gray-300 text-gray-600 text-sm rounded-lg block w-full p-2.5 focus:bg-white ${maxHeight} 
              ${isValidationObject && validator
              ? "border-red-400 hover:border-red-500 focus:border-red-500 focus:ring-red-500"
              : "border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring-blue-500"
            }
          `}
          id={id}
          name={name}
          placeholder={!simple || !simplestate ? placeholder : title}
          value={value}
          rows={rows}
          onChange={onChange}
          disabled={disabled}
        />

        {isValidationObject && validator && (
          <div className="absolute inset-y-0 right-0 -top-48 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleOutlined
              className="absolute inset-y-0 right-3 flex items-center text-red-500"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextArea;
