import { Button, CheckBox, Container, Header, Input } from '../../../../../core/components';
import { MailOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { UtilService } from '../../../../../core/services/util.service';
import { ICheckboxOption } from '../../../../../core/interfaces';
import { ProfileService } from '../service/profile.service';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import { User } from '../model/user.model';
import { Country } from '../../../../../core/models/components/country.model';
import Select from "react-select";

interface Option {
  value: string,
  label: React.ReactNode
}

const Profile: React.FC = () => {
  const countryOptions: Option[] = require("../../../../../assets/countries.json").map((country: any) => ({
    value: country.code,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={country.flag}
          alt={country.name}
          style={{ marginRight: "10px", width: "20px", height: "15px" }}
        />
        ({country.code})
      </div>
    )
  }));

  const defaultCountry: Option = countryOptions.find((option: any) => option.value === "CR")!;
  const [selectedCountry, setSelectedCountry] = useState<Option | null>(defaultCountry);

  const [user, setUser] = useState<User>(new User());

  const emailNotifiable: ICheckboxOption = { name: "ckbEmail", label: "Email", value: "ckbEmail", icon: <MailOutlined />, disabled: true };
  const whatsappNotifiable = { name: "ckbWhatsApp", label: "WhatsApp", value: "ckbWhatsApp", icon: <PhoneOutlined />, disabled: false };

  const [selecteds, setSelecteds] = useState<ICheckboxOption[]>([emailNotifiable]);
  const options = [emailNotifiable, whatsappNotifiable];

  const Get = async () => {
    let countries = (require("../../../../../assets/countries.json") as Country[]);

    let user = await ProfileService.Get();
    
    const updateds = [emailNotifiable];

    if (user.whatsappNotifiable) {
      updateds.push(whatsappNotifiable)
    }

    const phoneNumber = user.phoneNumber;

    setUser({
      ...user,
      phoneNumber: phoneNumber.slice(-8)
    });

    const country = countries.find((option: Country) => option.dial_code === phoneNumber.slice(0, phoneNumber.length - 8));

    const finded: Option = countryOptions.find((option: any) => option.value === country?.code) ?? defaultCountry;

    setSelectedCountry(finded)

    setSelecteds(updateds);
  }

  useEffect(() => {
    Get();
  }, []);

  const OnChangeSelected = (e: CheckboxChangeEvent, option: ICheckboxOption) => {
    setSelecteds((prevSelecteds) => {
      if (e.target.checked) {
        return [...prevSelecteds, option];
      } else {
        return prevSelecteds.filter((item) => item.value !== option.value);
      }
    });
  };

  const Validate = (user: User) => {
    const errors: string[] = [];

    if (user.firstname.length === 0) {
      errors.push("You must enter the firtsname.");
    }

    if (user.lastname.length === 0) {
      errors.push("You must enter the lastname.");
    }

    if (user.email.length === 0) {
      errors.push("You must enter the email.");
    }

    return errors;
  }

  const Patch = async () => {
    const errors = Validate(user);

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));

      return;
    }

    let country = (require("../../../../../assets/countries.json") as Country[]).find(x => x.code === (selectedCountry?.value ?? defaultCountry?.value))!;

    await ProfileService.Patch({
      ...user,
      emailNotifiable: selecteds.some(x => x.value === "ckbEmail"),
      whatsappNotifiable: selecteds.some(x => x.value === "ckbWhatsApp"),
      phoneNumber: `${country.dial_code}${user.phoneNumber}`,
    })

    await Get();

    UtilService.Alert('Success!', 'success', 'The user has been update.');
  }

  const OnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
  };

  return (
    <Container>
      <Header
        title='My Profile'
        subtitle='Administration'
        buttons={[]}
      />

      <div>
        <div className="grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5">
          <Input
            title='Firts Name'
            placeholder='Enter the firts name...'
            type='string'
            id='firstname'
            name="firstname"
            value={user.firstname}
            OnChange={OnChangeInput}
            validation={{
              Validator: () => user.firstname === ''
            }}
          />

          <Input
            title='Last Name'
            placeholder='Enter the last name...'
            type='string'
            id='lastname'
            name="lastname"
            value={user.lastname}
            OnChange={OnChangeInput}
            validation={{
              Validator: () => user.lastname === ''
            }}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 mb-1 gap-x-5 gap-y-5">
          <Input
            title='Email'
            placeholder='Enter the email...'
            type='email'
            id='email'
            name="email"
            value={user.email}
            OnChange={OnChangeInput}
            validation={{
              Validator: () => user.email === ''
            }}
          />

          <div>
            <div className='flex'>
              <span className="text-left block mb-2 text-sm font-medium text-gray-600">Phone Number</span>
              <span className="text-left block mb-2 text-sm font-medium text-red-600 ms-1">(*)</span>
            </div>

            <div className="grid grid-cols-12 mb-5 gap-x-2 gap-y-2">
              <div className='col-span-12 xs:col-span-4 sm:col-span-4 lg:col-span-5'>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0.5rem",
                      borderColor: "#d1d5db",
                      boxShadow: "none",
                      "&:hover": { borderColor: "#9ca3af" }
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: "0.5rem"
                    })
                  }}
                  options={countryOptions}
                  onChange={handleCountryChange}
                  placeholder="Select a country"
                  value={selectedCountry}
                />
              </div>

              <div className='col-span-12 xs:col-span-8 sm:col-span-8 lg:col-span-7'>
                <Input
                  placeholder='Enter the phone number...'
                  type='string'
                  id='email'
                  name="phoneNumber"
                  value={user.phoneNumber}
                  OnChange={OnChangeInput}
                  validation={{
                    Validator: () => user.phoneNumber === ''
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 mb-5 gap-x-5 gap-y-3">
          <div className='flex items-center justify-start mt-0'>
            <CheckBox
              title="Be notified On"
              options={options}
              selected={selecteds}
              onChange={OnChangeSelected}
              layout="horizontal"
            />
          </div>
        </div>

        <div className='flex items-center justify-end mt-6'>
          <Button
            icon={{ value: <SaveOutlined />, size: 'text-lg' }}
            text={{ value: 'Update', weight: 400, size: 'text-md' }}
            color='blue'
            tone={500}
            disabled={Validate(user).length !== 0}
            onClick={() => Patch()}
          />
        </div>
      </div>
    </Container>
  );
};

export default Profile;