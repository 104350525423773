import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { ColorsClasses, Colors, Tones, Weights, WeightsClasses } from '../constants';
import { UtilService } from '../services/util.service';
import { Dropdown as AntDropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';

interface DropdownItem {
  icon?: React.ReactNode;
  visible: boolean;
  disabled?: boolean;
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  icon?: {
    value: React.ReactNode;
    size: string;
  };
  text?: {
    value: string;
    weight: Weights;
    size: string;
  };
  color: Colors;
  tone: Tones;
  items: DropdownItem[];
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ icon, text, color, tone, items, disabled = false }) => {
  const utils = UtilService.GetInstance();
  const [transition, setTransition] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const subscription = utils.OnTransition().subscribe((transition) => {
      setTransition(transition);
    });

    return () => subscription.unsubscribe();
  }, [utils]);

  const colorClass = ColorsClasses[color]?.[tone] || 'bg-gray-500 hover:bg-gray-600';
  const weightClass = WeightsClasses[text?.weight ?? 400];

  const menu = (
    <Menu disabled={transition}>
      {items.filter((x) => x.visible === true).map((item, index) => (
        <Menu.Item key={index} 
          onClick={() => {
            item.onClick();
            setOpen(false);
          }} 
          className="hover:bg-gray-100 hover:text-gray-900"
          disabled={item.disabled}
        >
          {item.icon && <span className="mr-2">{item.icon}</span>}
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntDropdown
      overlay={menu}
      disabled={transition}
      onVisibleChange={(visible) => setOpen(visible)}
    >
      <button
        className={`h-8 text-white py-2 rounded flex items-center justify-center transition duration-300 ${colorClass} px-2`}
        disabled={transition}
      >
        {icon && <span className={`${text ? 'mr-2' : ''} ${icon.size}`}>{icon.value}</span>}
        {text && <span className={`${weightClass} ${text.size}`}>{text.value}</span>}

        <span className="mx-2 h-full w-px bg-gray-400"></span>

        <RightOutlined
          className={`transform transition-transform duration-300 ${open ? 'rotate-90' : 'rotate-0'
            }`}
        />
      </button>
    </AntDropdown>
  );
};

export default Dropdown;