import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IValidation } from '../../interfaces';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { Form } from 'antd';
import './Quill.css';

interface Props {
    title: string;
    placeholder: string;
    id: string;
    inputClass?: string;
    value: string;
    name: string;
    simple?: boolean;
    simplestate?: boolean;
    OnChange?: (name: string, value: string) => void;
    validation?: IValidation | boolean;
}

const Quill: React.FC<Props> = ({ title, placeholder, id, inputClass, value, simple = false, simplestate = false, name, OnChange, validation }) => {
    const isValidationObject = typeof validation === 'object' && validation !== null;
    const validator = isValidationObject ? validation?.Validator?.() : validation === true;

    return (
        <div className={`${inputClass}`}>
            {
                (!simple || !simplestate) && (
                    <div className='flex'>
                        <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>

                        {
                        validation !== null && (isValidationObject || (typeof validation === "boolean") && validation) && (
                            <span className="text-left block mb-2 text-sm font-medium text-red-600 ms-1">(*)</span>
                        )
                        }
                    </div>
                )
            }

            <div className="relative">
                <Form className='rounded-xl'>
                    <Form.Item className='rounded-xl'>
                        <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={(value) => OnChange?.(name, value)}
                            className={`rounded-xl ${isValidationObject && validator ? "error-quill" : ""}`}
                            placeholder={!simple || !simplestate ? placeholder : title}
                            id={id}
                            style={{
                                height: '300px',
                            }}
                        />
                    </Form.Item>
                </Form>

                {
                    isValidationObject && validator && (
                        <div className="absolute inset-y-0 right-0 -top-48 flex items-center pr-3 pointer-events-none">
                            <ExclamationCircleOutlined
                            className="absolute inset-y-0 right-3 top-2 flex items-center text-red-500"
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Quill;
