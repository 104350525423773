import { publish } from "rxjs";
import { Job } from "../../../../../core/models/jobs/job.model";
import { BaseService } from "../../../../../core/services/base.service";
import { UtilService } from "../../../../../core/services/util.service";
import { JobMetrics } from "../../../../public/login/models/jobMetrics.model";

export class JobService {
    static async Get(): Promise<Job[]> {
        return await BaseService.Get("/featured/jobs");
    }

    static async GetJob(id: string, CallbackCatch?: (error: Error) => void): Promise<Job> {
        let callbackcatch = (error: Error) => {
            if (["400", "404"].includes(error.message)) {
                UtilService.Alert('Important!', 'info', 'The job entered does not exist');
            } else {
                UtilService.Alert('Important!', 'info', 'Internal Server Error');
            }
        };

        if(CallbackCatch){
            callbackcatch = CallbackCatch;
        }

        return await BaseService.Get(`/public/jobs/${id}`, callbackcatch);
    }

    static async GetMetrics(id: string): Promise<JobMetrics> {
        return await BaseService.Get(`/public/jobs/${id}/metrics`, (error: Error) => {
            if (["400", "404"].includes(error.message)) {
                UtilService.Alert('Important!', 'info', 'The job entered does not exist');
            } else {
                UtilService.Alert('Important!', 'info', 'Internal Server Error');
            }
        });
    }

    static async Add(job: Job): Promise<Job> {
        let toAdd: Partial<Job>  = {
            name: job.name,
            category: job.category,
            subCategory: job.subCategory,
            description: job.description,
            location: job.location,
            company: job.company,
        };

        return await BaseService.Post("/featured/jobs", toAdd);
    }

    static async Edit(job: Job, id: string): Promise<Job> {
        let toAdd: Partial<Job>  = {
            name: job.name,
            category: job.category,
            subCategory: job.subCategory,
            description: job.description,
            location: job.location,
            company: job.company,
            draft: job.draft,
            published: job.published
        };

        return await BaseService.Patch(`/featured/jobs/${id}`, toAdd);
    }

    static async Publish(id: string): Promise<Job[]> {
        return await BaseService.Patch(`/jobs/${id}/featured/true`);
    }

    static async Delete(id: string): Promise<Job[]> {
        return await BaseService.Delete(`/jobs/${id}`);
    }
}