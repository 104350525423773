import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IButton, IValidation } from '../interfaces';
import Button from './Button';
import React from 'react';

interface Props {
  title?: string;
  placeholder?: string;
  type: string;
  id?: string;
  inputClass?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  simple?: boolean;
  simplestate?: boolean;
  buttons?: IButton[];
  OnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation?: IValidation | boolean;
}

const Input: React.FC<Props> = ({ title, placeholder = '', type, id = '', inputClass = '', value = '', name = '', disabled, simple = false, simplestate = false, buttons, OnChange, validation }) => {
  const isValidationObject = typeof validation === 'object' && validation !== null;
  const validator = isValidationObject ? validation?.Validator?.() : validation === true;

  return (
    <div className={`${inputClass}`}>
      {
        (!simple || !simplestate) && title && (
          <div className='flex'>
            <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>

            {
              validation !== null && (isValidationObject || (typeof validation === "boolean") && validation) && (
                <span className="text-left block mb-2 text-sm font-medium text-red-600 ms-1">(*)</span>
              )
            }
          </div>
        )
      }

      <div className={`${buttons ? 'flex align-middle justify-center w-100' : ''}`}>
        {
          isValidationObject && validator && (
            <div className="relative">
              <input
                className={`
                  bg-white border border-gray-300 text-gray-600 text-sm rounded-lg  block w-full py-1.5 px-2 focus:outline-none
                  ${isValidationObject && validator ? "border-red-400 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-blue-500 focus:ring-blue-500"}
                `}
                type={type}
                id={id}
                placeholder={!simple || !simplestate ? placeholder : title}
                value={value}
                name={name}
                onChange={OnChange}
                disabled={disabled}
              />

              {
                isValidationObject && validator && (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <ExclamationCircleOutlined
                      className="absolute inset-y-0 right-3 flex items-center text-red-500"
                    />
                  </div>
                )
              }
            </div>
          ) 
        }

        {
          !(isValidationObject && validator) && (
            <input
              className={`
                bg-white border border-gray-300 text-gray-600 text-sm rounded-lg  block w-full py-1.5 px-2 focus:outline-none
                ${isValidationObject && validator ? "border-red-400 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-blue-500 focus:ring-blue-500"}
              `}
              type={type}
              id={id}
              placeholder={!simple || !simplestate ? placeholder : title}
              value={value}
              name={name}
              onChange={OnChange}
              disabled={disabled}
            />
          ) 
        }


        {
          buttons?.map((b, index) => (
            <Button
              key={index + 1}
              icon={b.icon}
              text={b.text}
              color={b.color}
              tone={b.tone}
              disabled={b.disabled}
              btnClass={`${b.btnClass} ms-2`}
              onClick={b.onClick}
              title={b.title}
            />
          ))
        }
      </div>

    </div>
  );
};

export default Input;