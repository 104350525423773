import { Select } from 'antd';
import React from 'react';
import './Select.css'
import { IValidation } from '../../interfaces';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    title: string;
    placeholder: string;
    options: OptionType[];
    name: string;
    selectClass?: string;
    value: string;
    simple?: boolean;
    simplestate?: boolean;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
    validation?: IValidation | boolean;
}

const CustomSelect: React.FC<Props> = ({ title, placeholder, options, name, selectClass, value, simple = false, simplestate = false, onChange, disabled = false, validation }) => {
    const isValidationObject = typeof validation === 'object' && validation !== null;
    const validator = isValidationObject ? validation?.Validator?.() : validation === true;

    return (
        <div className={`${selectClass}`}>
            {
                (!simple || !simplestate) && (
                    <div className='flex'>
                        <span className="text-left block mb-2 text-sm font-medium text-gray-600">{title}</span>

                        {
                            validation !== null && (isValidationObject || (typeof validation === "boolean") && validation) && (
                                <span className="text-left block mb-2 text-sm font-medium text-red-600 ms-1">(*)</span>
                            )
                        }
                    </div>
                )
            }

            <Select
                showSearch
                className={`${(isValidationObject && validator) ? "error-select" : ""}  w-full h-[34px] disabled:opacity-50 disabled:bg-gray-100 disabled:text-gray-400 ${disabled ? 'opacity-100 bg-gray-white text-black' : ''}`}
                placeholder={!simple || !simplestate ? placeholder: title}
                value={value || undefined}
                onChange={(value) => onChange(name, value)}
                allowClear={false}
                disabled={disabled}
                suffixIcon={(isValidationObject && validator) ? 
                    <ExclamationCircleOutlined className="flex items-center text-red-500" style={{ fontSize: "15px" }}/> : 
                    <DownOutlined style={{ color: validator ? '#ef4444' : '#6b7280' }} />
                }
                filterOption={(input:any, option:any) =>
                    (option?.key ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    (option?.value ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                 }
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{
                    textAlign: 'left',
                    background: "white",
                    backgroundColor: disabled ? '#f3f4f6' : 'white',
                    color: disabled ? '#9ca3af' : 'black',
                }}
            >
                {options.map((option) => (
                    <Option key={option.label} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default CustomSelect;
