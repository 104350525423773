import { useJobStore } from '../../../../../core/store/job.store';
import { Job } from '../../../../../core/models/jobs/job.model';
import { Container } from '../../../../../core/components';
import { useNavigate, useParams } from 'react-router-dom';
import { JobService } from '../services/job.service';
import React, { useEffect, useState } from 'react';
import 'quill/dist/quill.bubble.css';
import Swal from 'sweetalert2';
import './JobPreview.css';

const JobPreview: React.FC = () => {
    const [job, setJob] = useState<Job>(new Job());
    const { id } = useParams<{ id?: string }>();
    const stored = useJobStore.getState().getJob();

    const navigate = useNavigate();

    const GetJob = async (id: string) => {
        return await JobService.GetJob(id);
    };
 
    useEffect(() => {
        const Initialize = async () => {
            try {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                if (!id && !stored) {
                    navigate("/private/job")

                    return;
                }

                if (id) {
                    let job = await GetJob(id);

                    setJob(job);
                } else if (stored) {
                    const now = new Date();

                    setJob({
                        ...stored,
                        postedDate: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`
                    });
                }
            } catch (error: any) {
                if (error.message.includes("SHOW: ")) {
                    navigate(`/job`);

                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'info',
                        title: error.message.slice(5, error.message.length),
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                }
            }
        };

        Initialize();
    }, []);

    return (
        <Container>
            <div className='pb-20 pt-24 px-6 sm:pt-28 sm:px-14 xl:pt-16 xl:px-48 w-full text-start'>
                <div className='w-full bg-gray-50 h-12 fixed top-10 block md:hidden'></div>
                <p className='text-3xl md:text-6xl'>{job.name}</p>
                <div className='mt-10 space-y-4'>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Company:</span>
                        <span>{job.company}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Location:</span>
                        <span>{job.location}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Category:</span>
                        <span>{job.category}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Subcategory:</span>
                        <span>{job.subCategory}</span>
                    </div>
                    <div className='flex'>
                        <span className='font-semibold w-32'>Posted on:</span>
                        <span>{job.postedDate}</span>
                    </div>
                </div>

                <p className='text-3xl md:text-xl mt-10 font-bold'>Job Description</p>

                <hr className="border-t border-gray-300 my-4" />

                <div className="ql-container ql-bubble preview">
                    <div
                        className="ql-editor"
                        style={{padding: '0!important'}}
                        dangerouslySetInnerHTML={{ __html: job.description }}
                    />
                </div>
            </div>
        </Container>
    );
};

export default JobPreview;